import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    NavigationCancel,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {
    filter,
    Observable,
} from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { BasePermissionsService } from '~/app/shared/services/base-permissions/base-permissions.service';
import { BinaryOperator } from '~/app/shared/types/binary-operator.type';

@Injectable({
    providedIn: 'root',
})
export class AuthorisationGuard {
    private previousUrl: string | null = null;

    constructor(
        private authorisationService: BasePermissionsService,
        private authorisationFacade: AuthorisationFacade,
        private router: Router,
        private location: Location,
    ) {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationCancel) {
                    this.previousUrl = event.url;
                }
            });
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const routeDataPermissions = route.data.permissions as string[];
        const binaryOperator = (route.data.binaryOperator ?? 'AND') as BinaryOperator;

        return this.authorisationFacade.permissionsLoaded$
            .pipe(
                tap((loaded) => {
                    if (!loaded) this.authorisationFacade.getAuthorisations();
                }),
                filter((loaded) => loaded),
                tap(() => {
                    if (routeDataPermissions?.length > 0) {
                        const isAllowed = this.authorisationService.hasPermissions(routeDataPermissions, null, binaryOperator);
                        if (isAllowed) {
                            return true;
                        }
                        // eslint-disable-next-line no-console
                        console.warn(`No permissions defined for route ${state.url}`);
                        if (this.previousUrl === null) {
                            this.location.back();
                        }
                        return false;
                    }
                    return this.authorisationService.hasPermissions(routeDataPermissions);
                }),
            );
    }
}
